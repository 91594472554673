export const FETCH_DATA = "fetchData";
export const SEARCH_AVAILABLE_DISTRIBUTION_AREAS = "availableDistributionAreas";
export const SEARCH_DISTRIBUTION_AREAS_BY_POSTCODES =
  "searchDistributionAreasByPostcodes";
export const SEARCH_DISTRIBUTION_AREAS_BY_NUMBER_OF_COPIES =
  "searchDistributionAreasByNumberOfCopies";
export const SEARCH_DISTRIBUTION_AREAS_BY_RADIUS =
  "searchDistributionAreasByRadius";
export const SEARCH_SUGGESTION = "searchSuggestion";
export const SUBMIT = "submit";
