<template>
  <div id="app" :class="{ embeded: embeded }">
    <FadeTransition group tag="div">
      <router-view :key="$route.name" />
    </FadeTransition>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { createHelpers } from "vuex-map-fields";
import { FadeTransition } from "vue2-transitions";

import { FETCH_DATA } from "@/store/action-types";

const { mapActions } = createNamespacedHelpers("configuration");
const { mapFields } = createHelpers({
  getterType: "configuration/getField",
  mutationType: "configuration/updateField",
});

export default {
  name: "App",
  components: { FadeTransition },
  meta() {
    const title = this.$t("Welcome");
    const siteName = this.$t("Media Planner");
    return {
      title,
      titleTemplate: (chunk) => `${chunk} | ${siteName}`,
    };
  },
  computed: {
    ...mapFields(["embeded"]),
  },
  created() {
    const query = new URLSearchParams(window.location.search.substring(1));
    if (query.has("embed")) {
      this.embeded = true;
    }
    this.fetchConfig();
  },
  methods: {
    ...mapActions({ fetchConfig: FETCH_DATA }),
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/app.scss";
</style>
