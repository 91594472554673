import axios from "axios";

export default {
  install(Vue) {
    Vue.$api = axios.create({
      baseURL: process.env.VUE_APP_API_URL,
      paramsSerializer: this.paramsSerializer,
    });
    Vue.prototype.$api = Vue.$api;
  },
  paramsSerializer(params) {
    const searchParams = new URLSearchParams();
    Object.keys(params).forEach((key) => {
      const value =
        typeof params[key] === "object"
          ? JSON.stringify(params[key])
          : params[key];
      searchParams.set(key, value);
    });
    return searchParams.toString();
  },
};
