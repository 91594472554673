import { getField, updateField } from "vuex-map-fields";

import api from "@/services/api";
import { FETCH_DATA } from "../action-types";
import { ERROR, SUCCESS, SET_DATA } from "../mutation-types";

const state = {
  data: {},
  embeded: false,
};

const getters = {
  getField,
  embeded(state) {
    return state.embeded;
  },
  headerLogo(state) {
    return state.data ? state.data.headerLogo : "";
  },
  footerLogo(state) {
    return state.data ? state.data.footerLogo : "";
  },
  initialCenter(state) {
    return state.data && state.data.initialCenter
      ? state.data.initialCenter.split(",").map((item) => parseFloat(item))
      : [49.37747443902155, 7.8062209021300095];
  },
  initialZoom(state) {
    return state.data ? parseInt(state.data.zoomLevel) : 9;
  },
  searchDefaults(state) {
    return state.data.conditions || {};
  },
  footerLinks(state) {
    if (state.data && state.data.footerLinks) {
      return state.data.footerLinks;
    }
    return [];
  },
  termsLink(state) {
    return (
      state.data.termsLink ||
      "https://www.wochenblatt-reporter.de/s/datenschutz"
    );
  },
  products(state) {
    return state.data.products;
  },
  productHeaders(state) {
    if (!state.data.products) {
      return {};
    }
    return state.data.products.reduce((headers, product) => {
      product.volumes.forEach(({ key, name, headerName }) => {
        headers[key] = `${headerName || name}`;
      });
      return headers;
    }, {});
  },
  productComments(state) {
    if (!state.data.products) {
      return {};
    }
    return state.data.products.reduce((comments, product) => {
      product.volumes.forEach(({ key, marker, notify: message }) => {
        comments[key] = { marker, message };
      });
      return comments;
    });
  },
  productOptions(state) {
    if (!state.data.products) {
      return [];
    }
    return state.data.products.reduce((options, item) => {
      item.volumes.forEach(({ name: text, key: value }) => {
        options.push({ text, value });
      });
      return options;
    }, []);
  },
  productPrices(state) {
    if (!state.data.products) {
      return {};
    }
    return state.data.products.reduce((prices, product) => {
      product.volumes.forEach((volume) => {
        prices[volume.key] = volume.price.map((price) => {
          return {
            ...price,
            priceWithTax: price.price * 1.19,
            tax: price.price * 0.19,
          };
        });
      });
      return prices;
    }, {});
  },
  homepageImages(state) {
    if (!state.data || !state.data.homepage) {
      return [];
    }
    return state.data.homepage;
  },
  addressPlaceholder(state) {
    if (state.data.addressPlaceholder) {
      return state.data.addressPlaceholder;
    }
    return "67059 Ludwigshafen";
  },
};

const mutations = {
  updateField,
  [SET_DATA](state, data) {
    state.data = data || {};
  },
};

const actions = {
  [FETCH_DATA]({ commit }) {
    api
      .get("/configuration/list")
      .then((res) => {
        commit(SET_DATA, res.data.configurations);
        commit(SUCCESS, null, { root: true });
        return res;
      })
      .catch((error) => {
        console.log({ error });
        commit(ERROR, error, { root: true });
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
