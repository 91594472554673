import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import i18n from "./locale";
import plugins from "./plugins";

Vue.config.productionTip = false;

plugins.forEach((plugin) => Vue.use(plugin));

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
