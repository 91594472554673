import { getField, updateField } from "vuex-map-fields";

import { RESET } from "../mutation-types";

const defaultFormData = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  company: "",
  street: "",
  postCode: "",
  location: "",
  clientNumber: "",
  requestedDate: "",
  comment: "",
  terms1: false,
  terms2: false,
};

const state = {
  form: {
    ...defaultFormData,
  },
};
const getters = {
  getField: (state) => {
    return getField(state.form);
  },
  form(state) {
    return state.form;
  },
};
const mutations = {
  updateField: (state, field) => {
    return updateField(state.form, field);
  },
  [RESET](state) {
    state.form = {
      ...defaultFormData,
    };
  },
};
const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
