import {
  LControlZoom,
  LGeoJson,
  LMap,
  LMarker,
  LPolygon,
  LTileLayer,
  LTooltip,
} from "vue2-leaflet";
import "leaflet/dist/leaflet.css";

export default {
  install(Vue) {
    Vue.component("LControlZoom", LControlZoom);
    Vue.component("LGeoJson", LGeoJson);
    Vue.component("LMap", LMap);
    Vue.component("LMarker", LMarker);
    Vue.component("LPolygon", LPolygon);
    Vue.component("LTileLayer", LTileLayer);
    Vue.component("LTooltip", LTooltip);
  },
};
