import parseWkt from "wellknown";
import { getField, updateField } from "vuex-map-fields";
// import _ from "lodash";

import api from "@/services/api";

import {
  SEARCH_AVAILABLE_DISTRIBUTION_AREAS,
  SEARCH_DISTRIBUTION_AREAS_BY_NUMBER_OF_COPIES,
  SEARCH_DISTRIBUTION_AREAS_BY_POSTCODES,
  SEARCH_DISTRIBUTION_AREAS_BY_RADIUS,
  SEARCH_SUGGESTION,
} from "../action-types";
import {
  ADD_ITEMS,
  ERROR,
  RESET,
  RESET_SUGGESTIONS,
  SET_AVAILABLE_AREAS,
  SET_SUGGESTIONS,
  SUCCESS,
  TOGGLE_ITEM,
} from "../mutation-types";

const state = {
  avaliableAreaItems: [],
  items: [],
  newspapers: ["volume1"],
  suggestions: [],
};

const getters = {
  getField,
  currentGeomCompositionIds(state) {
    return state.items
      .filter((item) => !!item.geomCompositionsid)
      .map((item) => item.geomCompositionsid)
      .join(",");
  },
  polygonGeoJson(state) {
    return state.items
      .filter((item) => !!item.wkt)
      .map((item) => {
        const geoJson = parseWkt(item.wkt);
        return {
          type: "Feature",
          geometry: geoJson,
          properties: {
            code: item.plz,
            name: item.ort,
          },
        };
      });
  },
  markersGeoJson(state) {
    const filter = (item) => !!item.geomCompositionsid;
    const mapper = (item) => {
      return {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [item.lon, item.lat],
        },
        properties: {
          item,
          iconUrl: require("@/assets/images/map_marker_green.png"),
        },
      };
    };
    return state.items.filter(filter).map(mapper);
  },
  availableMarkersGeoJson(state, getters) {
    const filter = (item) =>
      getters.currentGeomCompositionIds
        .split(",")
        .indexOf(item.geomCompositionsid) === -1;
    const mapper = (item) => {
      return {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [item.lon, item.lat],
        },
        properties: {
          item,
          iconUrl: require("@/assets/images/map_marker_red.png"),
        },
      };
    };
    return state.avaliableAreaItems.filter(filter).map(mapper);
  },
  items(state) {
    return state.items;
  },
  newspapers(state) {
    return state.newspapers;
  },
  suggestions(state) {
    return state.suggestions;
  },
};

const mutations = {
  updateField,
  [SUCCESS](state) {
    state.success = true;
    state.error = false;
  },
  [ERROR](state, error) {
    state.success = false;
    state.error = error.message;
  },
  [ADD_ITEMS](state, items) {
    const currentIds = state.items
      .filter((item) => !!item.plz)
      .map((item) => item.plz);
    const newItems = items.filter(
      (item) => currentIds.indexOf(item.plz) === -1
    );
    state.items = [...state.items, ...newItems];
    // state.items = _.sortBy(
    //   [...state.items, ...newItems],
    //   [(item) => !item.geomCompositionsid]
    // );
  },
  [TOGGLE_ITEM](state, item) {
    const currentIds = state.items.map((item) => item.plz);
    if (currentIds.indexOf(item.plz) === -1) {
      state.items.push(item);
    } else {
      state.items = state.items.filter(
        (currentItem) => currentItem.plz !== item.plz
      );
    }
  },
  [RESET](state) {
    state.items = [];
    state.success = false;
    state.error = false;
  },
  [SET_AVAILABLE_AREAS](state, items) {
    state.avaliableAreaItems = items;
  },
  [SET_SUGGESTIONS](state, items) {
    state.suggestions = items;
  },
  [RESET_SUGGESTIONS](state) {
    state.suggestions = [];
  },
};

const actions = {
  [SEARCH_AVAILABLE_DISTRIBUTION_AREAS]({ commit }, params) {
    return api
      .get("/search/searchAvailableGeometry", params)
      .then((res) => {
        commit(SET_AVAILABLE_AREAS, res.data.data);
        commit(SUCCESS, null, { root: true });
        return res;
      })
      .catch((error) => {
        console.log({ error });
        commit(ERROR, error, { root: true });
        return error;
      });
  },
  [SEARCH_DISTRIBUTION_AREAS_BY_POSTCODES]({ commit }, params) {
    return new Promise((resolve, reject) => {
      return api
        .get("/search/searchGeometryByPLZOrt", params)
        .then((res) => {
          if (res.data.data.nonExist.length) {
            const error = new Error("Invalid Address");
            const addresses = res.data.data.nonExist
              .map((item) => `${item.plz} ${item.ort}`.trim())
              .join("\n");
            error.data = { addresses };
            reject(error);
          }
          commit(ADD_ITEMS, res.data.data.exist);
          commit(SUCCESS, null, { root: true });
          resolve(res);
        })
        .catch((error) => {
          console.log({ error });
          commit(ERROR, error, { root: true });
          reject(error);
        });
    });
  },
  [SEARCH_DISTRIBUTION_AREAS_BY_RADIUS]({ commit }, params) {
    return new Promise((resolve, reject) => {
      api
        .get("/search/searchGeometryByRadius", params)
        .then((res) => {
          if (res.data.data) {
            commit(ADD_ITEMS, res.data.data);
            commit(SUCCESS, null, { root: true });
            resolve(res);
          } else {
            reject(new Error("Invalid Address"));
          }
        })
        .catch((error) => {
          console.log({ error });
          commit(ERROR, error, { root: true });
          reject(error);
        });
    });
  },
  [SEARCH_DISTRIBUTION_AREAS_BY_NUMBER_OF_COPIES]({ commit }, params) {
    return new Promise((resolve, reject) => {
      api
        .get("/search/searchGeometryByAuflage", params)
        .then((res) => {
          if (res.data.data) {
            commit(ADD_ITEMS, res.data.data);
            commit(SUCCESS, null, { root: true });
            resolve(res);
          } else {
            reject(new Error("Invalid Address"));
          }
        })
        .catch((error) => {
          console.log({ error });
          commit(ERROR, error, { root: true });
          reject(error);
        });
    });
  },
  [SEARCH_SUGGESTION]({ commit }, params) {
    return api
      .get("/search/suggestion", params)
      .then((res) => {
        commit(SET_SUGGESTIONS, res.data.data);
        return res;
      })
      .catch((error) => {
        console.log({ error });
        commit(ERROR, error, { root: true });
        return error;
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
