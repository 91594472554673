import Vue from "vue";
import VueI18n from "vue-i18n";
import en from "./en/messages.json";
import de from "./de/messages.json";

Vue.use(VueI18n);

export const messages = { en, de };
export const locales = { de: "Deutsch", en: "English " };
export const localeOptions = Object.keys(locales).map((key) => ({
  text: locales[key],
  value: locales[key],
}));
const numberFormats = {
  de: {
    currency: {
      style: "currency",
      currency: "EUR",
    },
    decimal: {
      style: "decimal",
      minimumFractionDigits: 2,
    },
  },
};

export default new VueI18n({
  locale: "de",
  messages,
  numberFormats,
  silentTranslationWarn: true,
});
