import Vue from "vue";

import i18n from "@/locale";

export default {
  getConfig(params = {}) {
    return {
      headers: {
        "Accept-Language": `${i18n.locale}`,
        "Content-Type": "application/json",
      },
      params: {
        ticketId: process.env.VUE_APP_API_TICKET_ID,
        ...params,
      },
    };
  },
  errorHanding(error) {
    console.log({ error });
  },
  get(resource, params = {}) {
    const config = this.getConfig(params);
    return Vue.$api.get(resource, config).catch(this.errorHanding);
  },
  post(resource, data = {}) {
    const config = this.getConfig();
    return Vue.$api
      .post(
        resource,
        { ticketId: process.env.VUE_APP_API_TICKET_ID, ...data },
        config
      )
      .catch(this.errorHanding);
  },
};
