import { RESET_SUGGESTIONS } from "../mutation-types";

export default (store) => {
  store.subscribe((mutation) => {
    if (
      mutation.type === "distributionAreas/toggleItem" ||
      mutation.type === "distributionAreas/addItems"
    ) {
      store.commit(`distributionAreas/${RESET_SUGGESTIONS}`);
    }
  });
};
