import api from "@/services/api";

import { SUCCESS, ERROR } from "../mutation-types";
import { SUBMIT } from "../action-types";

const actions = {
  [SUBMIT]({ commit }, data) {
    return api
      .post("plan/savePlan", data)
      .then((res) => {
        commit(SUCCESS, null, { root: true });
        return res;
      })
      .catch((error) => {
        commit(ERROR, error, { root: true });
        return error;
      });
  },
};

export default {
  namespaced: true,
  actions,
};
