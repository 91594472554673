import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () =>
      import(/* webpackChunkName: "home" */ "@pages/PHome/PHome.vue"),
  },
  {
    path: "/media-planning",
    name: "MediaPlanning",
    component: () =>
      import(
        /* webpackChunkName: "media-panning" */ "@pages/PMediaPlanning/PMediaPlanning.vue"
      ),
  },
  {
    path: "/about-us",
    name: "AboutUs",
    component: () =>
      import(/* webpackChunkName: "about-us" */ "@pages/PAboutUs/PAboutUs.vue"),
  },
  {
    path: "/contact",
    name: "Contact",
    component: () =>
      import(/* webpackChunkName: "contact" */ "@pages/PContact/PContact.vue"),
  },
  {
    path: "/review",
    name: "Review",
    component: () =>
      import(/* webpackChunkName: "review" */ "@pages/PReview/PReview.vue"),
  },
];

const router = new VueRouter({
  // mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
