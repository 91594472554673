import Vue from "vue";
import Vuex from "vuex";
import { getField, updateField } from "vuex-map-fields";

import distributionAreas from "./modules/distributionAreas";
import registrationForm from "./modules/registrationForm";
import plan from "./modules/plan";
import configuration from "./modules/configuration";

import distributionAreasPlugin from "./plugins/distributionAreas";

import { SUCCESS, ERROR } from "./mutation-types";

Vue.use(Vuex);

const state = {
  error: false,
  success: false,
  sidebarVisible: true,
};

const getters = {
  getField,
  success(state) {
    return state.success;
  },
  error(state) {
    return state.error;
  },
  sidebarVisible(state) {
    return state.sidebarVisible;
  },
};

const mutations = {
  updateField,
  [SUCCESS](state) {
    state.success = true;
    state.error = false;
  },
  [ERROR](state, error) {
    state.success = false;
    state.error = error.message;
  },
};

const actions = {};

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  modules: {
    distributionAreas,
    registrationForm,
    plan,
    configuration,
  },
  plugins: [distributionAreasPlugin],
  strict: process.env.NODE_ENV !== "production",
});
